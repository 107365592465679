const defaultTheme = {
    // Mint
    primary: {
      light: '#64EDD3',
      main: '#31D5BC',
      dark: '#40B29F',
      contrastText: '#222',
    },
    // Blue
    secondary: {
      light: '#447EAD',
      main: '#3A6893',
      dark: '#305A77',
      contrastText: '#fff',
    },
    // Dark
    tertiary: {
      light: '#414E59',
      main: '#303942',
      dark: '#1C2126',
      contrastText: '#fff',
    },
    // Navy
    accent: {
      light: '#405670',
      main: '#324259',
      dark: '#232F3D',
      contrastText: '#fff',
    },
    // Red
    error: {
      light: '#F95858',
      main: '#E24C4C',
      dark: '#C44747',
      contrastText: '#fff',
    },
    // Yellow / Tangerine
    warning: {
      light: "",
      main: "#F1BC4B",
      dark: "",
      contrastText: '#222'
    },
    text: {
      primary: '#333',
      secondary: '',
      inverted: '#FFF',
      disabled: '',
      hint: '',
    },
    background: {
      lighter: '#ffffff',
      light: '#f9f9f9',
      main: '#f1f1f1',
      dark: '#cacaca',
    },
    grey: {
      g1: '#222222',
      g2: '#333333',
      g3: '#4F4F4F',
      g4: '#686868',
      g5: '#A9A9A9',
      g6: '#CBCBCB',
      g7: '#DCDCDC',
      g8: '#EDEDED',
    }
  };
  export default defaultTheme;