const sizes = {
    tiny: '500px',
    small: '688px',
    medium: '922px',
    large: '1312px'
}

export const device = {
    tiny: `min-width: ${sizes.tiny}`,
    small: `min-width: ${sizes.small}`,
    medium: `min-width: ${sizes.medium}`,
    large: `min-width: ${sizes.large}`
}

const values = { sizes, device }

export default values;